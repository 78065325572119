import React, { useState } from "react"
import Layout from "../components/layout"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

const PosebnaPonuda = ({ data }) => {
  const intl = useIntl()
  const [lightbox1, setLightbox1] = useState(false)
  const [lightbox2, setLightbox2] = useState(false)

  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "posebna-ponuda" })}
      />
      <div className="section blue-background posebna-ponuda">
        <div className="content">
          <div className="card">
            <h1>{intl.formatMessage({ id: "posebna-ponuda" })}</h1>

            <div className="ponuda-container">
              {/*<div className="ponuda-card">
                <div className="ponuda-card-container">
                  <div className="ponuda-slika">
                    <Img
                      fluid={{
                        ...data.ponuda1.childImageSharp.fluid,
                        aspectRatio: 1.1,
                      }}
                    />
                  </div>
                  <div>
                    <div className="ponuda-naslov">
                      <h2>{intl.formatMessage({ id: "ponuda1.naslov" })}</h2>
                    </div>
                    <div className="ponuda-opis">
                      <p>
                        <FormattedMessage
                          id="ponuda1.opis"
                          values={{
                            p: (...chunks) => <p>{chunks}</p>,
                            br: <br />,
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                        </div> */}
              <div className="ponuda-card">
                <div className="ponuda-card-container">
                  <div className="ponuda-slika">
                    <Img
                      fluid={{
                        ...data.ponuda3.childImageSharp.fluid,
                        aspectRatio: 1.1,
                      }}
                    />
                  </div>
                  <div>
                    <div className="ponuda-naslov">
                      <h2>{intl.formatMessage({ id: "ponuda3.naslov" })}</h2>
                    </div>
                    <div className="ponuda-opis">
                      <FormattedMessage
                        id="ponuda3.opis"
                        values={{
                          p: (...chunks) => <p>{chunks}</p>,
                          br: <br />,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ponuda-card">
                <div className="ponuda-card-container">
                  <div className="ponuda-slika">
                    <Img
                      fluid={{
                        ...data.romina.childImageSharp.fluid,
                        aspectRatio: 1.1,
                      }}
                    />
                    <Img
                      style={{ marginTop: "1rem" }}
                      fluid={{
                        ...data.ponuda2.childImageSharp.fluid,
                        aspectRatio: 1.1,
                      }}
                    />
                  </div>
                  <div>
                    <div className="ponuda-naslov">
                      <h2>{intl.formatMessage({ id: "ponuda2.naslov" })}</h2>
                    </div>
                    <div className="ponuda-opis">
                      <FormattedMessage
                        id="ponuda2.opis"
                        values={{
                          p: (...chunks) => <p>{chunks}</p>,
                          br: <br />,
                        }}
                      />

                      <a
                        href="https://rovinjguide.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "block", marginTop: "0.5rem" }}
                      >
                        <FormattedMessage
                          id="ponuda2.link"
                          values={{
                            p: (...chunks) => <p>{chunks}</p>,
                            br: <br />,
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PosebnaPonuda

export const query = graphql`
  query {
    ponuda1: file(relativePath: { eq: "ponude/3=4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ponuda2: file(relativePath: { eq: "ponude/razgled.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ponuda3: file(relativePath: { eq: "ponude/prijevoz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    romina: file(relativePath: { eq: "ponude/romina.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
